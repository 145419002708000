import React from "react"

// import BackgroundImage from "gatsby-background-image"

import Layout from "../components/blogLayout"
import SEO from "../components/seo"

export default () => {
  // const { previous, next } = data

  return (
    <Layout>
      <SEO title={"Terms"} />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className={"container mx-auto  lg:px-48  pt-40"}>
          <div className={"bg-light text-primary m-5"}>
            <div className={"lg:p-12 p-6"}>
              <div className={"flex mb-5  h-full"}>
                <h1
                  className={"font-black md:text-3xl text-2xl text-primary"}
                  itemProp="headline"
                >
                  Terms
                </h1>
              </div>
              <section
                dangerouslySetInnerHTML={{
                  __html: `
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We, Referment Ltd., would like to help you monetise your network and generate goodwill by identifying suitable candidates for positions that we are working on for our clients.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Structure of Offer</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Referment Limited (&ldquo;Referment&rdquo;) relies on referrals from our network. Your referral must complete a face-to-face interview with a hiring company through Referment for you to receive up to &pound;500/AUD$700 as an interview reward. Telephone interviews do not qualify for referral rewards. The reward will be paid in the form of vouchers, credit or transfer.&nbsp;</span></p>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Standard referral rewards are &pound;250/AUD$450. Bronze rewards are &pound;300/AUD$500, Silver rewards are &pound;350/AUD$550, Gold rewards are &pound;400/AUD$600, Platinum rewards are &pound;450/AUD$650 and Referminator rewards are &pound;500/AUD$700. At this stage successful referrals are worth &pound;500/AUD$700 indefinitely with changes at Referment&rsquo;s discretion.&nbsp;</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Referral Ownership</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You will have complete ownership of the candidates you refer within our secure platform for 6 months. If your referral gets an interview through Referment during this 6-month period, you will receive the interview reward associated with your rewards level.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Payment Details</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Referment will pay you the interview reward within 48 hours of your referral&rsquo;s completion of a face-to-face interview. Payment is made in the form of vouchers via a secure email link. Our operations team will reach out to you when a reward is due, asking which reward you would like to receive. Rewards currently include credit from Uber, Uber Eats, PayPal or vouchers from Virgin Experience Days, Amazon, John Lewis and Partners, Secret Escapes, Selfridges and Co, Harrods, Eurostar, Four Seasons.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tax Liabilities</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Referment will not be liable for any tax payments owed by you. All interview rewards should be declared in a self-assessment tax return.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Information Rights</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Referment agrees to keep all of your details and the details of anyone you refer for internal use only and we will not share any of those details with any third parties, with the exception of our clients who will require your referral&rsquo;s details for interview processes. Information is stored on our CRM (Bullhorn) and on our platform database, which is hosted on Amazon Web Services.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">GDPR Consent</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All personal data that Referment may use will be collected, processed and held in accordance with the provisions of EU Regulation 2016/679 General Data Protection Regulation (&ldquo;GDPR&rdquo;) and your rights under the GDPR.</span></p>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For complete details of how we collect, process, store and retain personal data including, but not limited to, the purpose or purposes for which personal data is used, the legal basis or bases for using it, details of your rights and how to exercise them, and personal data sharing (where applicable), please refer to our Privacy Notice, which can be found on our homepage.</span></p>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In order to process your referral, we will need to make contact with the individual you have referred to see if they are interested in the role/company we are working with. As a referrer, you are responsible for ensuring that the information provided is accurate and the person you have referred is happy to be contacted by Referment.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Expenses</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Referment is not liable for any expenses that you may incur in engaging with your network or otherwise.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Exclusivity</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We know that we are able to provide you with a unique opportunity to put your efforts in building your network over the years to good use. We hope that you do not feel the need to engage in other referral schemes, and we may feel sad if you do, but we want you to be as successful as possible and therein by using our platform and referral scheme you are in no way locked into an exclusive agreement with Referment .</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Confidentiality</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We trust your judgement in deciding who to talk to about the information that Referment provides you. We would hope that you do not share any information with other recruitment businesses or recruiters as we know that they will try and place their own candidates into our roles. This will only decrease your chance of receiving a referral reward. In some cases, information provided to you may be confidential at the request of our client and in such circumstances we request that you do not relay the information provided to you to anyone who is not required to know such information.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Client Information</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Referment agrees to provide you, the referrer, with as much information about the role and person specification as possible. We will not discuss any information with you that we feel compromises our relationship with the client or yourself.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Expiry</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We hope you&rsquo;ll decide relatively quickly on whether or not you want to move ahead with us. Obviously, it&rsquo;s a big decision so please don&rsquo;t take it lightly, but time is your most valuable asset right now and the quicker we move forward and get your commitment, the quicker you&rsquo;ll be able to focus on making successful referrals.</span></p>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></h3>
                  <h3 dir="ltr" style="line-height:1.44;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:18pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Termination</span></h3>
                  <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Avenir,sans-serif;color:#212121;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If you are found to be supplying other recruitment companies with any information given to you by Referment, we have the right to terminate these terms with immediate effect. You also have the right to remove yourself from our referral network if you no longer wish to participate.</span></p>
                  <p><br></p>
                  <p><br></p>
                `,
                }}
              ></section>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}
